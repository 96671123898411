.home-container {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
}

body {
  background: url(../../src/images/tech_support.png) no-repeat center center
    fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
}

.text-input-field {
  padding: 24px 12px;
  border-radius: 20px;
  font-size: 24px;
  width: 70%;
  align-self: center;
  color: rgba(0, 0, 0, 0.6);
}

.text-input-field:focus {
  outline: none;
}

.enter-room-button {
  margin-top: 20px;
  padding: 24px 12px;
  font-size: 28px;
  background: #0a5c70;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  border-radius: 20px;
  width: 20%;
  height: 100%;
  align-self: center;
}
