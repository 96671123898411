.chat-room-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.room-name {
  margin-top: 0;
  text-align: center;
  color: white;
  opacity: 0.7;
}

.messages-container {
  flex: 1;
  min-height: 100px;
  overflow: auto;
  border: 1px solid #0a5c70;
  border-radius: 7px 7px 0 0;
}

.messages-list {
  list-style-type: none;
  padding: 0;
}

.new-message-input-field {
  height: 200px;
  max-height: 50%;
  font-size: 20px;
  padding: 8px 12px;
  resize: none;
}

.message-item {
  width: 55%;
  margin: 8px;
  padding: 12px 8px;
  word-break: break-word;
  border-radius: 4px;
  color: white;
}

.my-message {
  background-color: rgb(102, 80, 151);
  margin-left: auto;
}

.received-message {
  background-color: #29437c;
  margin-right: auto;
}

.file-item {
  width: 100%;
}

.my-file {
  width: 100%;
  margin-left: 90%;
}

.received-file {
  width: 100%;
  margin-left: 2%;
}
.send-message-button {
  font-size: 28px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
  background: #0a5c70;
  padding: 24px 12px;
  border: none;
}

.sessions-button {
  font-size: 18px;
  font-weight: 300;
  color: white;
  background: #0a5c70;
  padding: 24px 12px;
  width: 200px;
  height: 10px;
  border: none;
}

.messages-container,
.new-message-input-field,
.send-message-button {
  border-color: #9a9a9a;
}
